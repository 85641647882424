import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UserEntity as User } from '../Entities/UserEntity';
import { Teams } from '../Entities/TeamEntity';
import { folderDetails } from '../components/Screens/ReusuableComponents/ModalBoxes/ScenarioModals/NewFolder.modal';
import { Session } from '../components/Screens/Sessions/Sessions';

const instance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const baseURL = window.location.origin;
      if (window.location.href !== `${baseURL}/`) {
        window.location.href = `${baseURL}/`;
      }
    }
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response);
    }
    return Promise.reject(error);
  }
);

export const setAccessToken = (accessToken: string): void => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

export const clearAccessToken = (): void => {
  delete instance.defaults.headers.common['Authorization'];
};

export const getUserFilesByUserId = (
  userId: string
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/users/file/${userId}`);
};

export const createNewUser = (user: User): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.post('/users', user);
};

export const deleteUserByUserId = (userId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.delete(`/users/${userId}`);
};

export const getUserByUserId = (userId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/users/${userId}`);
};

export const getAuthenticatedUser = (): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/me`);
};

export const getAllUsers = (): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/users`);
};

export const getAllTeams = (): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/teams`);
};

export const getTeamById = (teamId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/teams/team/${teamId}`);
};

export const getCoachById = (coachId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`coaches/${coachId}`);
};

export const getTeamFilesByTeamId = (
  teamId: string
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/teams/file/${teamId}`);
};

export const updateUserByUserId = (
  userId: string,
  userBody: User
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.put(`/users/${userId}`, userBody);
};

export const updatePlayerByUserId = (
  userId: string,
  playerBody: User
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.put(`/players/${userId}`, playerBody);
};

export const updateCoachByUserId = (
  userId: string,
  coachBody: User
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.put(`/coaches/${userId}`, coachBody);
};

export const updateTeamByTeamId = (teamData: Teams): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  const teamId = teamData.teamId;
  return instance.put(`/teams/${teamId}`, teamData);
};

export const deleteTeamById = (teamId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.delete(`/teams/${teamId}`);
};

export const getAllScenarios = (): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`/scenarios/`);
};

export const getScenario = (scenarioId: string): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get(`scenarios/${scenarioId}`);
};

export const createFolder = (
  folderDetails: folderDetails
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.post(`/scenarios`, folderDetails);
};

export const postScenario = (scenarioDetails: any): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.post('/scenarios', scenarioDetails);
};

export const deleteScenarioById = (
  scenarioId: string
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.delete(`/scenarios/${scenarioId}`);
};

// Sessions

export const getAllSessions = (): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.get('/sessions');
};

export const deleteSessionById = (
  sessionId: string
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.delete(`/sessions/${sessionId}`);
};

export const createSession = (
  sessionRequest: Session
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.post(`/sessions`, sessionRequest);
};

export const updateSessionBySessionId = (
  sessionRequest: Session,
  sessionId: string
): Promise<AxiosResponse> => {
  const accessToken =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  setAccessToken(accessToken || '');
  return instance.put(`/sessions/${sessionId}`, sessionRequest);
};
