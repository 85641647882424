import React, { useEffect, useState } from 'react';

interface InternetProps {
  children: React.ReactNode;
}

function InternetCheck({ children }: InternetProps) {
  const [hasInternet, setHasInternet] = useState(true);

  const checkConnectivity = () => {
    const isOnline = navigator.onLine;
    setHasInternet(isOnline);
    localStorage.setItem('hasInternet', JSON.stringify(isOnline));
  };

  useEffect(() => {
    const setStatus = localStorage.getItem('hasInternetConnectivity');
    if (setStatus !== null) {
      setHasInternet(JSON.parse(setStatus));
    }
    window.addEventListener('online', checkConnectivity);
    window.addEventListener('offline', checkConnectivity);
    return () => {
      window.removeEventListener('online', checkConnectivity);
      window.removeEventListener('offline', checkConnectivity);
    };
  }, []);

  const appStyle: React.CSSProperties = {
    filter: hasInternet ? 'none' : 'grayscale(100%) opacity(0.6)',
    // position: 'relative',
    pointerEvents: hasInternet ? 'auto' : 'none',
  };

  return <div style={appStyle}>{children}</div>;
}

export default InternetCheck;
