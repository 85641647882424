export const TEAM_CATEGORIES = {
  MALE: 'male',
  FEMALE: 'female',
  NOT_SPECIFIED: 'Not Specified',
};

export const PLAYER_POSITIONS = {
  GOALKEEPER: 'GK',
  RIGHT_WING_BACK: 'RWB',
  RIGHT_BACK: 'RB',
  CENTER_BACK: 'CB',
  LEFT_BACK: 'LB',
  LEFT_WING_BACK: 'LWB',
  RIGHT_MIDFIELDER: 'RM',
  CENTER_DEFENSIVE_MIDFIELDER: 'CDM',
  CENTER_ATTACKING_MIDFIELDER: 'CAM',
  CENTER_MIDFIELDER: 'CM',
  LEFT_MIDFIELDER: 'LM',
  STRIKER: 'ST',
  RIGHT_WING: 'RW',
  RIGHT_FORWARD: 'RF',
  CENTER_FORWARD: 'CF',
  LEFT_FORWARD: 'LF',
  LEFT_WING: 'LW',
};

export const Player_Positions_To_Display = {
  GOALKEEPER: 'Goalkeeper - GK',
  RIGHT_WING_BACK: 'Right Wing Back - RWB',
  RIGHT_BACK: 'Right Back - RB',
  CENTER_BACK: 'Center Back - CB',
  LEFT_BACK: 'Left Back - LB',
  LEFT_WING_BACK: 'Left Wing Back - LWB',
  RIGHT_MIDFIELDER: 'Right Midfielder - RM',
  CENTER_DEFENSIVE_MIDFIELDER: 'Center Defensive Midfielder - CDM',
  CENTER_ATTACKING_MIDFIELDER: 'Center Attacking Midfielder - CAM',
  CENTER_MIDFIELDER: 'Center Midfielder - CM',
  LEFT_MIDFIELDER: 'Left Midfielder - LM',
  STRIKER: 'Striker - ST',
  RIGHT_WING: 'Right Wing - RW',
  RIGHT_FORWARD: 'Right Forward - RF',
  CENTER_FORWARD: 'Center Forward - CF',
  LEFT_FORWARD: 'Left Forward - LF',
  LEFT_WING: 'Left Wing - LW',
};

export const COACH_POSITIONS = {
  HEAD_COACH: 'HC',
  ASSISTANT_COACH: 'AC',
  GOALKEEPER_COACH: 'GKC',
  ATHLETICS_COACH: 'ACO',
  REHAB_COACH: 'RC',
  ANALYST: 'AN',
  VIDEO_ANALYST: 'VA',
  MATCH_ANALYST: 'MA',
  SPORT_PSYCHOLOGIST: 'SP',
};
export const positionOrder: { [position: string]: number } = {
  HEAD_COACH: 1,
  ASSISTANT_COACH: 2,
  GOALKEEPER_COACH: 3,
  ATHLETICS_COACH: 4,
  REHAB_COACH: 5,
  ANALYST: 6,
  VIDEO_ANALYST: 7,
  MATCH_ANALYST: 8,
  SPORT_PSYCHOLOGIST: 9,

  // PLAYERS
  GOALKEEPER: 10,
  RIGHT_WING_BACK: 11,
  RIGHT_BACK: 12,
  CENTER_BACK: 13,
  LEFT_BACK: 14,
  LEFT_WING_BACK: 15,
  RIGHT_MIDFIELDER: 16,
  CENTER_DEFENSIVE_MIDFIELDER: 17,
  CENTER_ATTACKING_MIDFIELDER: 18,
  CENTER_MIDFIELDER: 19,
  LEFT_MIDFIELDER: 20,
  STRIKER: 21,
  RIGHT_WING: 22,
  RIGHT_FORWARD: 23,
  CENTER_FORWARD: 24,
  LEFT_FORWARD: 25,
  LEFT_WING: 26,
};

export const Coach_Positions_To_Display = {
  HEAD_COACH: 'Head Coach',
  ASSISTANT_COACH: 'Assistant Coach',
  GOALKEEPER_COACH: 'Goalkeeper Coach',
  ATHLETICS_COACH: 'Athletics Coach',
  REHAB_COACH: 'Rehap Coach',
  ANALYST: 'Analyst',
  VIDEO_ANALYST: 'Video Analyst',
  MATCH_ANALYST: 'Match Analyst',
  SPORT_PSYCHOLOGIST: 'Sport Psychologist',
};

export const ROLES = {
  ADMIN: 'AN',
  PLAYER: 'PR',
  COACH: 'CH',
};

export const deleteUserConsent =
  'You are about to delete a user account. All the data associated with the user will also be deleted.';
export const sure = 'Are you sure?';

export const StrongFoot = {
  LEFT: 'Left',
  RIGHT: 'Right',
  BOTH: 'Both',
};

export const UserAccountManagement = 'User Account Management';
export const Profiles = 'Profiles';

export const sessionCreated = 'Session has been created successfully';
export const sessionUpdated = 'Session has been updated successfully';
export const sessionConfirmationHead = (title: string) => {
  if (title === 'success') {
    return 'Notification';
  } else {
    return 'Warning !!';
  }
};
export const emptyFields = 'Please fill all required fields';

export const Convert = 'Convert';
export const ModalConfirmationSave = 'Save';
export const ModalConfirmReject = 'Cancel';
export const ModalConfirmResolve = 'Okay';
export const ModalConfirmEdited = 'Save Changes';
export const RemoveUserFromTeam =
  '<b>Delete User:</b> Are you sure you want to delete this user? <br/>This can not be undone!';
export const DeleteTeamText =
  '<b>Delete Team:</b> Are you sure you want to delete this team? <br/> This can not be undone!';
export const ModalBottomBtnNewScenario = 'New Scenario';
export const FileSizeAndFileFormatText =
  'Max file size: 1MB; Supported file types: JPG, JPEG, PNG';
export const ChooseAndUploadPicture = 'Choose and upload picture';

export const SessionDeleteText =
  'Are you sure you want to delete <br/>this planned session? <br/>This can not be undone!';

export const ScenarioDeleteText =
  'Are you sure you want to delete this scenario?<br/>This can not be undone!';
export const ForgotPasswordBody =
  'Please contact Admin for a new Password.<br/>Thank you!';

export const LogoutModalText = 'Do you want to log out of your account?';
export const ChangesDoneText = 'All the changes have been updated';

export const ModalHeading_Options = {
  Confirmation: 'Confirmation',
  Information: 'Information',
  Notification: 'Notification',
  Edit_Fol_Details: 'Edit Folder Details',
  Create_New_Folder: 'Create New Folder',
  New_Scenario: 'New Scenario',
  View_Scenario: 'View Scenario',
  Edit_Scenario: 'Edit Scenario',
  Forgot_Password: 'Forgot Password?',
  Logout: 'Logout',
  Upload_Profile: 'Change Profile Picture',
  Error: 'Error',
  Session_Preview: 'Session Preview',
  Add_Scenario_To_Session: 'Add Scenarios to Session',
  Add_Attendees_To_Session: 'Add Attendees to Session',
  Unsaved_Changes: 'Unsaved changes',
  Success: 'Success',
};

// errors
export const NoScenariosText = 'No Scenarios / Folders Found';
export const FileBigErrorText = 'File could not be uploaded, file size is big';
export const TeamDeleteErrorText =
  'Team could not be deleted! Please try again.';
export const SessionDeleteErrorText =
  'Session could not be deleted! Please try again.';

export const ScenarioDeleteErrorText =
  'Scenario could not be deleted! Please try again.';

export const ChangesWillDiscard = 'Changes will be discarded';
export const NewTeamDiscardText = 'New Team will be discarded.';
export const ChangesSaved = 'Changes are saved';
export const NewTeamCreationErrorText =
  'New Team could not be created. Please try again.';
export const NewScenarioCreationErrorText =
  'New Scenario could not be created. Please try again.';
export const NewFolderCreationErrorText =
  'New Folder could not be created. Please try again.';
export const NewSessionCreationErrorText =
  'New Session could not be created. Please try again.';

export const NewUSerCreationErrorText =
  'New User could not be created. Please try again.';
export const NewUserDuplicateMailText =
  'A User with the same e-mail address already exists.';

export const ChangesNotSavedText =
  'Changes could not be saved. Please try again.';
export const DataImportErrorText =
  'The Uploaded file could not be converted to a scenario';

export const DataImportSuccessText =
  'Uploaded files was successfully converted to a scenario';

export const ViewScenario = 'View Scenario';

export const NoneText = 'None';
export const NewUserCreationSuccessText = 'New User was created';

// ContactUS
export const GetInTouchWithUs = 'Get in touch with us';
