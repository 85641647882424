import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Teams } from '../../Entities/TeamEntity';
import { ScenarioEntity } from '../../Entities/ScenarioEntity';
import { UserEntity as User } from '../../Entities/UserEntity';
import { AuthContext } from './AuthContext';
import {
  getAllScenarios,
  getAllSessions,
  getAllTeams,
  getAllUsers,
} from '../../services/axiosApi';
import { SessionEntity } from '../../Entities/SessionEntity';
import Spin from '../Loaders/Spin.loader';

interface UserDataType {
  teamsList: Teams[] | null | undefined;
  scenariosList: ScenarioEntity[] | null | undefined;
  allUsersList: User[] | null | undefined;
  sessionsList: SessionEntity[] | null | undefined;
  updateSessionsList: (allSessions: SessionEntity[] | null | undefined) => void;
  updateAllUsersList: (allUsers: User[] | null | undefined) => void;
  updateTeamsList: (teams: Teams[] | null | undefined) => void;
  updateScenarioList: (scenarios: ScenarioEntity[] | null | undefined) => void;
}

export const UserDataContext = createContext<UserDataType>({
  teamsList: null,
  scenariosList: null,
  allUsersList: null,
  sessionsList: null,
  updateSessionsList(allSessions) {},
  updateAllUsersList(allUsers) {},
  updateTeamsList(teams) {},
  updateScenarioList(scenarios) {},
});

export const UserDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [teamsList, setTeamsList] = useState<Teams[] | null | undefined>([]);
  const [scenariosList, setScenariosList] = useState<
    ScenarioEntity[] | null | undefined
  >([]);
  const [allUsersList, setAllUsersList] = useState<User[] | null | undefined>(
    []
  );
  const [sessionsList, setSessionsList] = useState<
    SessionEntity[] | null | undefined
  >([]);
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const updateSessionsList = (
    allSessions: SessionEntity[] | null | undefined
  ) => {
    setSessionsList(allSessions);
  };

  const updateAllUsersList = (allUsers: User[] | null | undefined) => {
    setAllUsersList(allUsers);
  };

  const updateTeamsList = (teams: Teams[] | null | undefined) => {
    setTeamsList(teams);
  };

  const updateScenarioList = (
    scenarios: ScenarioEntity[] | null | undefined
  ) => {
    setScenariosList(scenarios);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          teamsResponse,
          scenariosResponse,
          usersResponse,
          sessionsResponse,
        ] = await Promise.all([
          getAllTeams(),
          getAllScenarios(),
          getAllUsers(),
          getAllSessions(),
        ]);

        setTeamsList(teamsResponse.data);
        setScenariosList(scenariosResponse.data);
        setAllUsersList(usersResponse.data);
        setSessionsList(sessionsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <UserDataContext.Provider
      value={{
        teamsList,
        scenariosList,
        allUsersList,
        sessionsList,
        updateSessionsList,
        updateAllUsersList,
        updateTeamsList,
        updateScenarioList,
      }}
    >
      {isLoading ? (
        <Spin bgColor="transparent" blurPixels='blur("19px")' />
      ) : (
        children
      )}
    </UserDataContext.Provider>
  );
};
