import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { getAuthenticatedUser } from '../services/axiosApi';
import { AuthContext } from '../components/Context/AuthContext';
import { ROLES } from '../constants/apolloConstants';
import { UserEntity as User } from '../Entities/UserEntity';
import Spin from '../components/Loaders/Spin.loader';

const LoginPage = lazy(() => import('../components/login/LoginPage'));
const ContactUs = lazy(() => import('../components/ContactUs/ContactUs'));
const UserDetails = lazy(() => import('../components/Users/UserDetails'));
const EditorScreen = lazy(() => import('../components/Screens/Editor.screen'));
const ScenarioScreen = lazy(() =>
  import('../components/Screens/Scenario.screen')
);
const DataImport = lazy(() =>
  import('../components/Screens/DataImport.screen')
);
const Teams = lazy(() => import('../components/Screens/Teams.screen'));
const WebGLTest = lazy(() => import('../components/webGL/WebGL_Test'));
const PageNotFound = lazy(() =>
  import('../components/ErrorPages/PageNotFound')
);
const DetailedTeams = lazy(() =>
  import('../components/Screens/DetailedTeams.screen')
);
const UserAccountManagement = lazy(() =>
  import(
    '../components/Screens/UserAccountManagement/UserAccountManagement.screen'
  )
);
const CreateNewUserAccount = lazy(() =>
  import(
    '../components/Screens/UserAccountManagement/CreateNewUserAccount.screen'
  )
);
const DetailedProfilePopup = lazy(() =>
  import(
    '../components/Screens/ReusuableComponents/DetailedProfilePopup.component'
  )
);
const Profiles = lazy(() =>
  import('../components/Screens/Profiles/Profiles.screen')
);
const Sessions = lazy(() =>
  import('../components/Screens/Sessions/Sessions.screen')
);
const SessionsNew = lazy(() =>
  import('../components/Screens/Sessions/SessionsNew.screen')
);
const NotAuthorised = lazy(() =>
  import('../components/ErrorPages/NotAuthorised')
);
const CoachHomePage = lazy(() => import('../components/Home/CoachHomePage'));
const MyProfile = lazy(() =>
  import('../components/Screens/UserAccountManagement/MyProfile.screen')
);
const Settings = lazy(() => import('../components/Screens/Settings.screen'));

const AllRoutes = () => {
  const [userData, setUserData] = useState<User | null>(null);
  const { user } = useContext(AuthContext);

  const getCurrentUser = (): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      getAuthenticatedUser()
        .then((response: AxiosResponse) => {
          const userData: User = response.data;
          if (userData.userId) {
            setIsAuthenticated(true);
            setUserData(userData);
            resolve(true);
          } else {
            setIsAuthenticated(false);
            resolve(false);
          }
        })
        .catch(err => {
          console.log(err);
          setIsAuthenticated(false);
          resolve(false);
        });
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuthenticationChecked, setIsAuthenticationChecked] = useState<
    boolean
  >(false);

  useEffect(() => {
    getCurrentUser().then(result => {
      setIsAuthenticated(result);
      setIsAuthenticationChecked(true);
    });
  }, []);

  const renderWithAuthorizationCheck = (
    path: string,
    component: React.ReactNode,
    allowedRoles: string[]
  ) => {
    return (
      <Route
        path={path}
        element={
          isAuthenticationChecked ? (
            isAuthenticated && user?.role ? (
              allowedRoles.includes(user?.role) ? (
                component
              ) : (
                <NotAuthorised />
              )
            ) : (
              <Navigate to="/" />
            )
          ) : null
        }
      />
    );
  };

  return (
    <BrowserRouter>
      <Suspense
        fallback={<Spin bgColor="transparent" blurPixels='blur("19px")' />}
      >
        <Routes>
          <Route path="/contact" element={<ContactUs />} />
          {renderWithAuthorizationCheck('home', <CoachHomePage />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('webGLtest', <WebGLTest />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          <Route
            path="/"
            element={
              isAuthenticationChecked ? (
                isAuthenticated ? (
                  <Navigate to="/home" replace />
                ) : (
                  <LoginPage />
                )
              ) : null
            }
          />
          {renderWithAuthorizationCheck('/getdetails', <UserDetails />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/editor', <EditorScreen />, [
            ROLES.ADMIN,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/data_import', <DataImport />, [
            ROLES.ADMIN,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck(
            '/scenario_screen',
            <ScenarioScreen />,
            [ROLES.ADMIN, ROLES.PLAYER, ROLES.COACH]
          )}
          {renderWithAuthorizationCheck('/teams_screen', <Teams />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/sessions_screen', <Sessions />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck(
            '/sessions_screen/new',
            <SessionsNew />,
            [ROLES.ADMIN, ROLES.COACH]
          )}
          {renderWithAuthorizationCheck(
            '/teams_screen/:teamId',
            <DetailedTeams />,
            [ROLES.ADMIN, ROLES.PLAYER, ROLES.COACH]
          )}
          <Route
            path="/user_management"
            element={
              isAuthenticationChecked ? (
                isAuthenticated && userData?.role === ROLES.ADMIN ? (
                  <UserAccountManagement />
                ) : (
                  <Navigate to="/*" replace />
                )
              ) : null
            }
          />
          <Route
            path="/user_management/:userId/overview"
            element={
              isAuthenticationChecked ? (
                isAuthenticated && userData?.role === ROLES.ADMIN ? (
                  <DetailedProfilePopup />
                ) : (
                  <Navigate to="/*" replace />
                )
              ) : null
            }
          />
          <Route
            path="/user_management/new"
            element={
              isAuthenticationChecked ? (
                isAuthenticated && userData?.role === ROLES.ADMIN ? (
                  <CreateNewUserAccount />
                ) : (
                  <Navigate to="/*" replace />
                )
              ) : null
            }
          />

          <Route
            path="/profiles_screen"
            element={
              isAuthenticationChecked ? (
                isAuthenticated && userData?.role === ROLES.COACH ? (
                  <Profiles />
                ) : (
                  <Navigate to="/*" replace />
                )
              ) : null
            }
          />

          {renderWithAuthorizationCheck(
            '/profiles_screen/:userId/overview',
            <DetailedProfilePopup />,
            [ROLES.ADMIN, ROLES.PLAYER, ROLES.COACH]
          )}
          {renderWithAuthorizationCheck('/mprofile', <DetailedProfilePopup />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/myprofile/', <MyProfile />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/settings_beta/', <Settings />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
          {renderWithAuthorizationCheck('/*', <PageNotFound />, [
            ROLES.ADMIN,
            ROLES.PLAYER,
            ROLES.COACH,
          ])}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AllRoutes;
