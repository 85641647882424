import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { UserEntity as User } from '../../Entities/UserEntity';
import { getUserFilesByUserId, setAccessToken } from '../../services/axiosApi';
// Define the type for the AuthContext
interface AuthContextType {
  user: User | null;
  login: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>;
  logout: () => void;
  authUserImage: any;
}

// Create the AuthContext
export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: async () => {},
  logout: () => {},
  authUserImage: null,
});

// Create the AuthProvider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [authUserImage, setAuthUserImage] = useState();
  const token =
    sessionStorage.getItem('token') || localStorage.getItem('token');
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (token) {
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/me`,
            { headers }
          );
          const userDetails = response.data as User;
          setUser(userDetails);
          setAccessToken(token || '');
          const response2 = await getUserFilesByUserId(response.data.userId);
          setAuthUserImage(response2.data.data);
        }
      } catch (error) {
        // Handle errors, display error messages, etc.
        console.log('Unauthorized');
      }
    };

    fetchUserDetails();
  }, [token]);

  const login = async (
    username: string,
    password: string,
    rememberMe: boolean
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          userName: username,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const token = response.data.token;
      if (rememberMe) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }
      const userDetailsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userDetails = userDetailsResponse.data as User;
      setUser(userDetails);
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, authUserImage }}>
      {children}
    </AuthContext.Provider>
  );
};
