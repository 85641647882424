import React from 'react';
import styled from 'styled-components';

const PreloaderOverlay = styled.div<{ bgColor: string; blurPixels: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bgColor};
  backdrop-filter: ${props => props.blurPixels};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInAmin 0.7s ease-in;
  z-index: 9999999;

  @keyframes fadeInAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const PreloaderSpinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #e74576;
  border-top: 3px solid #282893;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type spinProps = {
  bgColor: string;
  blurPixels: string;
};
const Spin = (props: spinProps) => {
  return (
    <PreloaderOverlay bgColor={props.bgColor} blurPixels={props.blurPixels}>
      <PreloaderSpinner />
    </PreloaderOverlay>
  );
};

export default Spin;
