import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

interface ThemeProviderProps {
  children: ReactNode;
}

const theme = {
  colors: {
    HotPink: '#E74576',
    LightGreen: '#2EAB58',
    CristGreen: '#2E9D54',
    RoyalBlue: '#282893',
    DarkRoyal: '#1E1F5C',
    DarkBlue: '#1A1A42',
    BlackBlue: '#050A16',
    TextGray: '#939393',
    InputFieldOutlineGray: '#C1C1C1',
    ErrorColor: '#F80000',
    LargeError: '#D41313',
  },
  filters: {
    deleteIconHoverFilter:
      'invert(42%) sepia(100%) saturate(3016%) hue-rotate(315deg) brightness(85%) contrast(97%)',
  },
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
