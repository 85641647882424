import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import InternetCheck from './Features/InternetCheck.feature';
import AllRoutes from './Routes/AllRoutes';

function App() {
  return (
    <ThemeProvider>
      <InternetCheck>
        <AllRoutes />
      </InternetCheck>
    </ThemeProvider>
  );
}

export default App;
